const NotFound = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="bg-white rounded-[14px] border px-4 pb-3 w-[91%] md:pb-1 pt-2 m-2 sm:w-6/12">
        <div className="relative bg-white header-pattern">
          <section className="flex flex-col justify-center items-center py-12 max-w-4xl text-center md:py-12 container-box">
            <div className="sm:p-6"></div>
            <div className="flex flex-nowrap">
                <div className="font-black text-center text-gray-900 text-6xl sm:text-[10rem]">404</div>

                <div className="font-black text-center hidden sm:block text-gray-900 sm:pt-20" >Page Not Found<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Can't Find That Page!<br/><label className="font-normal">Go to <a href="/" className="text-blue-700 cursor-pointer 
underline">homepage</a></label>&nbsp;&nbsp;</div>

            </div>
            <div className="sm:hidden font-black text-center text-gray-900"> <br/>Page Not Found<br/>Can't Find That Page!<br/></div>
            <div className="sm:hidden">Go to <a href="/" className="text-blue-700 cursor-pointer 
underline">homepage</a></div>
            <div className="p-2  sm:p-8"></div>
              {/* <h1 className=" font-black text-center text-gray-900">404</h1><label>Page Not Found</label>
              <label></label> */}
            {/* <h1 className="text-7xl font-black text-center text-gray-900 md:text-5xl">404 Page Not Found</h1> */}
          </section>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
